@use "../global" as g;

/* ==========================================================================
		common
========================================================================== */

.container {
	width: 90%;
	max-width: g.$max-width;
	margin: 0 auto;
}

.contents {
	padding-bottom: 9rem;
	@include g.mq {
		padding-bottom: 6rem;
	}
}

.page__header {
	padding: 7rem 0 5rem;
	@include g.mq {
		padding: 5rem 0 3.5rem;
	}
	@include g.mq(sm) {
		padding: 3rem 0 2rem;
	}
}

.page__title {
	text-align: center;
	font-size: 4.8rem;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: -0.25px;
	@include g.mq {
		font-size: 3.8rem;
	}
	@include g.mq(sm) {
		font-size: 3rem;
	}
}

.main-section {
	&:not(:last-of-type) {
		padding: 9rem 0;
		@include g.mq {
			padding: 6rem 0;
		}
		@include g.mq(sm) {
			padding: 4rem 0;
		}
	}
	&:last-of-type {
		padding: 9rem 0 0;
		@include g.mq {
			padding: 6rem 0 0;
		}
		@include g.mq(sm) {
			padding: 4rem 0 0;
		}
	}
}

.section__title {
	font-size: 4rem;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: -0.25px;
	@include g.mq {
		font-size: 3.2rem;
		text-align: center;
	}
	@include g.mq(sm) {
		font-size: 2.4rem;
	}
	.page__usage-flow & {
		text-align: center;
		@include g.mq {
			font-size: 2.8rem;
		}
		@include g.mq(sm) {
			font-size: 2.4rem;
		}
	}
}

.section__sub-title {
	font-size: 1.8rem;
	font-weight: bold;
	@include g.mq(sm) {
		font-size: 1.6rem;
	}
}

.section__desc {
	line-height: 2.3;
	@include g.mq {
		text-align: left;
		font-size: 1.4rem;
		line-height: 2;
		letter-spacing: -0.02em;
	}
}

.section__text {
	font-size: 1.4rem;
	line-height: 2;
}

/* ボタン */
.button--common {
	display: block;
	text-align: center;
	font-size: 1.2rem;
	font-weight: bold;
	border: 1px solid #483519;
	border-radius: 3px;
	padding: 1.2rem 0;
	color: #483519;
	transition: all 0.3s;
	&:hover {
		background-color: rgba(72, 53, 25, 0.1);
	}
}