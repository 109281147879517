@use "../global" as g;

/* ==========================================================================
    for-question
========================================================================== */

.page__for-question {
	.questions {
		&__title {
			margin-bottom: 4.8rem;
			text-align: center;
		}
		&__content {
			padding-left: 6.4rem;
			@include g.mq {
				padding-left: 0;
			}
		}
		&__list {
			margin-bottom: 3.1rem;
		}
		&__item {
			&:not(:last-of-type) {
				margin-bottom: 4.1rem;
			}
		}
		&__question {
			font-size: 2.7rem;
			font-weight: 700;
			line-height: 1.19;
			margin-bottom: 0.8rem;
			@include g.mq {
				font-size: 2rem;
			}
		}
		&__answer {
			color: #666;
		}
		&__additional {
			font-size: 1.8rem;
			line-height: 1.78;
			@include g.mq {
				font-size: 1.6rem;
			}
		}
		&__link {
			color: g.$link-color;
		}
	}
}