@use "../global" as g;

/* ==========================================================================
    home
========================================================================== */

// ご利用の流れ 
.flow {
	&__footer {
		height: 9.5rem;
		margin-bottom: -9.5rem;
		background: linear-gradient(180deg, #f7f7f8 0, rgba(255, 255, 255, 0));
		transform: matrix(1, 0, 0, -1, 0, 0);
		@include g.mq {
			height: 6rem;
			margin-bottom: -6rem;
		}
	}
}
.flow__pc {
	@include g.mq {
		display: none;
	}
	&__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__content {
		width: 49%;
	}
	&__title {
		margin-bottom: 4rem;
		padding-left: 2.4rem;
	}
	&__step {
		max-width: 47.4rem;
		padding: 2.4rem;
		cursor: pointer;
		&.is-active {
			border-radius: 10px;
			transition: background-color 0.6s ease-in-out, opacity 0.6s ease-in-out;
			animation: bg-color 1s ease forwards;
			-webkit-animation: bg-color 1s ease forwards;
			&:hover {
				background: #e6f5f9;
				box-shadow: none;
			}
		}
		&:hover {
			background: #fff;
			box-shadow: 0 4px 32px rgba(0, 0, 0, 0.8);
			border-radius: 10px;
		}
		&:not(:last-of-type) {
			margin-bottom: 1.6rem;
		}
		&__title {
			font-size: 2.4rem;
			font-weight: 700;
		}
		&__desc {
			color: #4d4d4d;
		}
	}
	&__image {
		width: 49%;
		&__item {
			display: none;
			animation: img-fadeIn 1s;
			&.is-show {
				display: block;
			}
		}
	}
}

@keyframes bg-color {
	0% { background-color: transparent; }
	100% { background-color: #E7E0C5; }
}
@keyframes img-fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

.flow__sp {
	display: none;
	@include g.mq {
		display: block;
		&__inner {
			max-width: 36.6rem;
			margin: 0 auto;
		}
		&__step {
			&:not(:last-of-type) {
				border-bottom: 1px solid #ccc;
				padding: 4rem 0;
			}
			&:last-of-type {
				padding-top: 4rem;
			}
			&__text-area {
				cursor: pointer;
			}
			&__title-box {
				display: flex;
				justify-content: center;
			}
			&__title {
				font-size: 2.4rem;
				font-weight: 700;
				margin: 0 1.6rem 1.6rem 0;
				max-width: 37rem;
			}
			&__arrow {
				flex-shrink: 0;
				&.is-rotated img {
					transform: rotate(180deg);
				}
			}
			&__desc {
				color: #4d4d4d;
			}
			&__image {
				display: none;
				margin-top: 3rem;
				&.is-active {
					display: block;
				}
			}
		}
	}
}

// サービスエリア 
.service-area {
	@include g.mq {
		margin-bottom: 3rem;
	}
	&__inner {
		display: flex;
		position: relative;
		@include g.mq {
			flex-direction: column;
		}
	}
	&__content {
		min-width: 40rem;
		width: 37.8%;
		margin-right: 2rem;
		@include g.mq {
			width: auto;
			max-width: 47rem;
			min-width: auto;
			margin: 0 auto 4rem;
		}
	}
	&__title {
		margin-bottom: 3.2rem;
	}
	&__text {
		margin-bottom: 2.4rem;
	}
	&__list {
		max-width: 40rem;	
		margin: 0 auto;
	}
	&__item {
		display: inline-block;
		padding-right: 1.6rem;
	}
	&__check-icon {
		display: inline-block;
		top: 3px;
		position: relative;
		width: 16px;
		height: 16px;
		border-radius: 50px;
		background-color: #E7E0C5;
		margin-right: 8px;
		img {
			width: 16px;
			height: 16px;
			vertical-align: middle;
			position: absolute;
			bottom: 1px;
		}
	}
	// サービスエリア スライドショー 
	&__slide {
		&__container {
			max-width: 125rem;
			padding: 0.5rem 0 1.2rem 1.2rem;
			@include g.mq {
				width: 25.6rem;
				padding: 0;
				overflow: visible;
			}
		}
	}
	&__detail {
		width: 25.6rem;
		min-width: 23rem;
		min-height: 33rem;
		padding: 2.4rem 2.4rem 2.4rem;
		border: 1px solid #ebebeb;
		border-radius: 8px;
		background: #fff;
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
		&__header {
			display: flex;
			align-items: center;
			margin-bottom: 1.6rem;
		}
		&__img {
			width: 5.6rem;
			height: 5.6rem;
			margin-right: 1.6rem;
			img {
				border-radius: 3.2rem;
			}
		}
		&__area-name {
			font-size: 1.4rem;
			line-height: 1.43;
			font-weight: 700;
		}
		&__city-name {
			font-size: 1.4rem;
			line-height: 1.43;
			font-weight: 700;
			margin-bottom: 1rem;
		}
		&__desc {
			font-size: 1.4rem;
			line-height: 1.43;
			margin-bottom: 1.6rem;
		}
		&__customers {
			* {
				display: inline-block;
			}
			&__count {
				color: #00bf8f;
				font-weight: 700;
			}
		}
	}
	// サービスエリア スライドショー 
	.swiper-button-prev,
	.swiper-button-next {
		top: 44px;
		width: 20px;
		height: 20px;
		@include g.mq {
			display: none;
		}
	}
	.swiper-button-prev {
		background: url("../images/common/icon_arrow-prev.svg") no-repeat center center / contain;
		left: 34rem;
	}
	.swiper-button-next {
		background: url("../images/common/icon_arrow-next.svg") no-repeat center center / contain;
		left: 37rem;
	}
	.swiper-pagination {
		display: none;
		@include g.mq {
			display: block;
		}
	}
	.swiper-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: -5rem;
	}
} 

// 海外エージェントサービスなら 
.overseas-agent {
	@include g.mq {
		padding-bottom: 0;
	}
	&__title {
		margin-bottom: 6.4rem;
		text-align: center;
		@include g.mq {
			font-size: 3.2rem;
			margin-bottom: 3.2rem;
		}
	}
	&__inner {
		position: relative;
		max-width: 105.4rem;
		margin: 0 auto;
		padding: 6rem 0 6rem;
		border-radius: 8px;
		box-shadow: 0 4px 32px rgba(0, 0, 0, 0.16);
		@include g.mq {
			box-shadow: none;
			max-width: 60rem;
			padding: 0;
		}
	}
	&__content {
		&__list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
		}
		&__item {
			width: 18rem;
			@include g.mq(lg) {
				width: 32rem;
				&:nth-of-type(-n+2) {
					margin-bottom: 4.8rem;
				}
			}
			@include g.mq {
				&:nth-last-of-type(2) {
					margin-bottom: 4.8rem;
				}
			}
		}
		&__image {
			width: 16rem;
			height: 13rem;
			margin: 0 auto 1.4rem;
		}
		&__title {
			margin-bottom: 1.6rem;
			letter-spacing: 0.15px;
			text-align: center;
			@include g.mq {
				white-space: normal;
			}
		}
		&__desc {
			color: #4d4d4d;
		}
	}
}

// よくある質問 
.faq {
  @include g.mq {
		margin-bottom: 3rem;
	}
	&__inner {
		position: relative;
		@include g.mq(lg) {
			max-width: 75.7rem;
		}
	}
	&__pc {
		@include g.mq {
			display: none;
		}
	}
	&__content {
		display: grid;
		grid-template-rows: 1fr 24px 1fr;
		grid-template-columns: 1fr 24px 1fr 24px 1fr;
		@include g.mq(lg) {
			grid-template-rows: 1fr 24px 1fr 24px 1fr;
			grid-template-columns: 1fr 24px 1fr;
		}
		@include g.mq {
			display: none;
		}
		:first-child {
			grid-row: 1;
			grid-column: 1;
		}
		:nth-child(2) {
			grid-row: 1;
			grid-column: 3;
			@include g.mq {
				grid-row: 3;
				grid-column: 1;
			}
		}
		:nth-child(3) {
			grid-row: 1;
			grid-column: 5;
			@include g.mq(lg) {
				grid-row: 3;
				grid-column: 1;
			}
		}
		:nth-child(4) {
			grid-row: 3;
			grid-column: 1;
			@include g.mq(lg) {
				grid-row: 3;
				grid-column: 3;
			}
		}
		:nth-child(5) {
			grid-row: 3;
			grid-column: 3;
			@include g.mq(lg) {
				grid-row: 5;
				grid-column: 1;
			}
		}
		:nth-child(6) {
			grid-row: 3;
			grid-column: 5;
			@include g.mq(lg) {
				grid-row: 5;
				grid-column: 3;
			}
		}
	}
	&__title {
		align-self: center;
		letter-spacing: -0.25px;
		max-width: 33.6rem;
		text-align: center;
		@include g.mq(lg) {
			max-width: 36.5rem;
		}
		@include g.mq {
			font-size: 3.2rem;
			margin: 3.2rem auto 5.6rem;
		}
	}
	&__item {
		display: flex;
		flex-direction: column;
		min-width: 28.9rem;
		max-width: 38rem;
		padding: 2rem 4rem 4.2rem;
		border: 1px solid #dbdbdb;
		border-radius: 8px;
		color: #4d4d4d;
		@include g.mq(lg) {
			max-width: 36.5rem;
		}
		@include g.mq {
			height: 28rem;
			padding: 2.4rem 2.4rem 3rem;
			box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
		}
		&__icon {
			width: 5rem;
			margin-bottom: 1rem;
		} 
		&__text {
			min-width: 22.5rem;
			max-width: 25.6rem;
			height: 15rem;
		}
		&__title {
			padding-bottom: 1.4rem;
			letter-spacing: -0.03em;
		}
	}
	&__sp {
		display: none;
		@include g.mq {
			display: block;
		}
	}
	// よくある質問 スライドショー 
	&__slide {
		position: relative;
		&__container {
			width: 29rem;
			overflow: visible;
		}
	}
	.swiper-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: -5rem;
	}
}

// お知らせ 
.news {
	&__title {
		margin-bottom: 3rem;
	}
	&__list {
		padding: 3rem 3%;
		margin-bottom: 2rem;
		background-color: g.$bg-color;
		@include g.mq {
			padding: 2rem 3%;
		}
	}
	&__item {
		display: flex;
		padding: 1.5rem 0;
		border-bottom: 1px solid #EAEAEA;
		@include g.mq {
			flex-direction: column;
			text-align: center;
			padding: 1.5rem 3%;
		}
		&__date {
			font-size: 1.8rem;
			font-weight: bold;
      flex: 0 0 16.4%;
			@include g.mq {
				margin: 0 0 0.5rem;
			}
		}
		&__title {
			font-size: 1.8rem;
      flex: 1 1 auto;
			&._text-bold {
				font-weight: bold;
			}
		}
		&__detail {
			margin: 2rem 0;
			@include g.mq {
				margin: 2rem 0 1rem;
			}
			&__inner {
				display: flex;
				@include g.mq {
					flex-direction: column;
				}
			}
			&__image {
				width: 30%;
				margin-right: 2.4rem;
				@include g.mq {
					width: 100%;
					margin: 0 0 2rem;
				}
			}
		}
		&__text {
			font-size: 1.4rem;
		}
	}
	&__button {
		width: 18.5rem;
		margin-left: auto;
		@include g.mq {
			margin-right: auto;
			width: 100%;
		}
	}
}

// サービスエリア、よくある質問共通 スライドショー 
.service-area,
.faq {
	.swiper-pagination-bullet {
		width: 6px;
		height: 6px;
		background-color: #ccc;
		border-radius: 50%;
		transition: background-color 0.6s ease;
		opacity: 1;
		&-active,
		&:hover {
			border-color: g.$link-color;
			background-color: g.$link-color;
		}
	}
	.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 8px;
	}
}