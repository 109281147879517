@use "../global" as g;

/* ==========================================================================
	countries
========================================================================== */

.page__countries {
	.countries-area {
		text-align: center;
		&__title {
			margin-bottom: 4.8rem;
			@include g.mq {
				margin-bottom: 1.6rem;
			}
		}
		&__list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include g.mq {
				flex-direction: column;
			}
		}
		&__item {
			@include g.mq {
				display: flex;
			}
			&--ctw {
				width: 48%;
				@include g.mq {
					width: 100%;
				}
				&:not(:nth-last-of-type(-n+2)) {
					margin-bottom: 4rem;
					@include g.mq {
						margin-bottom: 3rem;
					}
				}
				&:not(:last-of-type) {
					@include g.mq {
						margin-bottom: 3rem;
					}
				} 
			}
			&--cth {
				width: 32%;
				@include g.mq {
					width: 100%;
				}
				&:not(:nth-last-of-type(-n+3)) {
					margin-bottom: 4rem;
					@include g.mq {
						margin-bottom: 3rem;
					}
				}
				&:not(:last-of-type) {
					@include g.mq {
						margin-bottom: 3rem;
					}
				} 
			}
			&__info-graphic {
				margin-bottom: 1.6rem;
				@include g.mq {
					flex-shrink: 0;
					width: 5.6rem;
					height: 5.6rem;
					margin-right: 2rem;
				}
			}
			&__content {
				@include g.mq {
					padding-top: 0.8rem;
				}
			}
			&__title {
				margin-bottom: 1.6rem;
				font-weight: 700;
				font-size: 2.4rem;
				line-height: 1;
				white-space: nowrap;
				@include g.mq {
					margin-bottom: 0.5rem;
					font-size: 2rem;
					line-height: 1.25;
					text-align: left;
				}
			}
			&__desc {
				color: #666;
				margin-bottom: 2.4rem;
				text-align: left;
				@include g.mq {
					margin-bottom: 1.5rem;
				}
			}
		}
		&__button {
			max-width: 24.5rem;
			margin: auto;
			@include g.mq {
				max-width: none;
				width: 100%;
			}
		}
	}

	.countries-questions {
		&__title {
			margin-bottom: 4.8rem;
			text-align: center;
		}
		&__content {
			max-width: 95rem;
			margin: 0 auto;
		}
		&__list {
			margin-bottom: 4rem;
		}
		&__item {
			&:not(:last-of-type) {
				margin-bottom: 4rem;
				@include g.mq {
					margin-bottom: 3rem;
				}
			}
		}
		&__question {
			margin-bottom: 0.5rem;
			font-weight: 700;
			font-size: 2.7rem;
			line-height: 1.5;
			@include g.mq {
				font-size: 2rem;
			}
		}
		&__answer {
			color: #666;
		}
		&__additional {
			font-size: 1.8rem;
			line-height: 1.78;
			@include g.mq {
				font-size: 1.6rem;
			}
		}
		&__link {
			color: g.$link-color;
		}
	}
}