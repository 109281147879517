@use "../global" as g;

/* ==========================================================================
    for-experts
========================================================================== */
.page__for-experts {
	.hiw {
		text-align: center;
		&__title {
			margin-bottom: 4.8rem;
			@include g.mq {
				margin-bottom: 1.6rem;
			}
		}
		&__step {
			display: flex;
			justify-content: space-between;
			@include g.mq {
				flex-direction: column;
			}
			&__item {
				width: 32%;
				@include g.mq {
					display: flex;
					width: 100%;
					&:not(:last-of-type) {
						margin-bottom: 1.6rem;
					}
				}
			}
			&__icon {
				margin-bottom: 1.6rem;
				@include g.mq {
					width: 5.6rem;
					height: 5.6rem;
					margin-right: 1.6rem;
					flex-shrink: 0
				}
			}
			&__content {
				@include g.mq {
					text-align: left;
					padding-top: 0.8rem;
				}
			}
			&__title {
				font-size: 2.4rem;
				font-weight: 700;
				line-height: 1;
				margin-bottom: 1.6rem;
				@include g.mq {
					font-size: 1.6rem;
					line-height: 1.25;
					margin-bottom: 0;
				}
			}
			&__desc {
				color: #666
			}
		}
	}
	.qualifications {
		background: g.$bg-color;
		padding: 9rem 0 0;
		&__inner {
			padding: 0 0 12rem 18.4rem;
			background-image: url('../images/for-experts/img_experts.png');
			background-repeat: no-repeat;
			background-position: right 7rem bottom;
			@include g.mq(xl) {
				padding: 5.3rem 0 11rem 6.5rem;
				background-position: right -3rem bottom;
				background-size: 40rem;
			}
			@include g.mq(lg) {
				padding: 4.8rem 0 5rem 2.4rem;
				background-size: 38rem;
			}
			@include g.mq {
				padding: 4.8rem 0 24rem;
				background-size: 32rem;
			}
			@include g.mq(sm) {
				padding-top: 2.4rem;
			}
		}
		&__title {
			margin-bottom: 7.5rem;
			letter-spacing: normal;
			@include g.mq(lg) {
				margin-bottom: 1.5rem;
			}
			@include g.mq(sm) {
				text-align: center;
			}
		}
		&__content {
			max-width: 47.6rem;
			@include g.mq(lg) {
				max-width: 34.3rem;
			}
			@include g.mq {
				max-width: 50rem;
			}
		}
		&__text {
			font-size: 2.4rem;
			line-height: 1.16;
			@include g.mq(lg) {
				font-size: 2rem;
			}
			@include g.mq {
				font-size: 1.6rem;
			}
			&:first-of-type {
				margin-bottom: 3.2rem;
				@include g.mq {
					margin-bottom: 1.6rem;
				}
			}
		}
	}
	.callout {
		text-align: center;
		color: #000;
		&__title {
			margin-bottom: 2.4rem;
			text-align: center;
		}
		&__desc {
			font-size: 2.2rem;
			line-height: 1.64;
			max-width: 80rem;
			color: #666;
			margin: 0 auto 7.9rem;
			text-align: left;
			@include g.mq {
				font-size: 1.6rem;
				line-height: 1.5;
				margin-bottom: 1.6rem;
			}
		}
		&__list {
			position: relative;
			margin-bottom: 8.6rem;
			@include g.mq {
				margin-bottom: 2.4rem;
			}
			&::after {
				content: "";
				position: absolute;
				z-index: -1;
				top: 4rem;
				bottom: 10rem;
				left: 50%;
				border-left: 2px solid #ccc;
				transform: translateX(-50%);
				@include g.mq {
					top: 4rem;
					left: 2.4rem;
				}
			}
		}
		&__item {
			display: flex;
			justify-content: space-between;
			max-width: 32rem;
			margin-right: auto;
			margin-left: auto;
			@include g.mq {
				margin-left: 0;
			}
			&:not(:last-of-type) {
				margin-bottom: 15.8rem;
				@include g.mq {
					margin-bottom: 3.2rem;
				}
			}
			&:nth-of-type(1),
			&:nth-of-type(3) {
				transform: translateX(42.5%);
				@include g.mq {
					transform: none;
				}
			}
			&:nth-of-type(2) {
				flex-direction: row-reverse;
				transform: translateX(-42.5%);
				@include g.mq {
					transform: none;
					flex-direction: row;
				}
			}
			&__icon {
				@include g.mq {
					flex-shrink: 0;
				}
			}
			&__content {
				max-width: 26.6rem;
				padding-top: 1rem;
				@include g.mq {
					padding-top: 1.5rem;
				}
				&--right {
					text-align: left;
				}
				&--left {
					text-align: right;
					@include g.mq {
						text-align: left;
					}
				}
			}
			&__title {
				font-size: 2.4rem;
				font-weight: 700;
				line-height: 1;
				margin-bottom: 0.9rem;
				@include g.mq {
					font-size: 1.6rem;
				}
			}
			&__desc {
				color: #666;
				@include g.mq {
					font-size: 1.4rem;
				}
			}
		}
		&__button {
			display: inline-block;
			font-size: 2.4rem;
			font-weight: 700;
			line-height: 2;
			color: #fff;
			background: #e85c41;
			padding: 1.2rem 2.4rem;
			transition: background-color 0.1s ease-in-out, color .1s ease-in-out, box-shadow 0.1s ease-in-out;
			cursor: pointer;
			-webkit-appearance: none;
			border-radius: 4px;
			@include g.mq {
				width: 100%;
				font-size: 1.6rem;
				line-height: 1.5;
			}
		}

	}
	.saying-about {
		background: url('../images/for-experts/bg_gradient.png')  no-repeat center center / cover;
		padding: 0;
		&__inner {
			padding: 9rem 0 9.9rem;
			position: relative;
			@include g.mq {
				padding-top: 6rem;
				padding-bottom: 7rem;
			}
			@include g.mq(sm) {
				padding: 4rem 0 7rem;
			}
		}
		&__title {
			color: #fff;
			margin-bottom: 4rem;
			padding: 0 1.6rem;
			text-align: center;
			@include g.mq {
				margin-bottom: 2.4rem;
			}
		}
		&__box {
			position: relative;
			background: #fff;
			border-radius: 4px;
			padding: 4rem;
			margin-bottom: 2.4rem;
			@include g.mq {
				margin-bottom: 1.6rem;
				padding: 1.6rem 1.6rem 2.4rem;
			}
			&::before {
				display: block;
				position: absolute;
				content: "";
				bottom: -4rem;
				left: 13.8%;
				width: 0;
				height: 0;
				border-top: 4rem solid transparent;
				border-bottom: 4rem solid transparent;
				border-left: 6rem solid #fff
			}
		}
		&__quote {
			line-height: 1.75;
			@include g.mq {
				font-size: 1.4rem;
				line-height: 1.71;
			}
		}
		&__cite {
			display: block;
			color: #fff;
			font-size: 1.4rem;
			text-align: right;
		}
		.swiper-pagination-bullet {
			width: 10px;
			height: 10px;
			margin-left: 8px;
			margin-right: 8px;
			cursor: pointer;
			border: 1px solid #fff;
			background-color: transparent;
			border-radius: 50%;
			display: inline-block;
			transition: background-color .2s ease-in-out;
			opacity: 1;
			&-active,
			&:hover {
				background: #fff
			}
		}
		.swiper-horizontal>.swiper-pagination-bullets,
		.swiper-pagination-bullets.swiper-pagination-horizontal,
		.swiper-pagination-custom,
		.swiper-pagination-fraction {
			bottom: 5.5rem;
			@include g.mq {
				bottom: 3rem;
			}
		}
	}
	.experts-questions {
		color: #333;
		&__title {
			margin-bottom: 4.8rem;
			text-align: center;
		}
		&__content {
			padding-left: 6.4rem;
			@include g.mq {
				padding-left: 0;
			}
		}
		&__list {
			margin-bottom: 3.1rem;
		}
		&__item {
			&:not(:last-of-type) {
				margin-bottom: 4.1rem;
			}
		}
		&__question {
			font-size: 2.7rem;
			font-weight: 700;
			line-height: 1.19;
			margin-bottom: 0.8rem;
			@include g.mq {
				font-size: 2rem;
			}
		}
		&__answer {
			color: #666;
		}
		&__additional {
			font-size: 1.8rem;
			line-height: 1.78;
			@include g.mq {
				font-size: 1.6rem;
			}
		}
		&__link {
			color: g.$link-color;
		}
	}
}