@use "../global" as g;

/* ==========================================================================
    external
========================================================================== */

.external {
	padding: 6.4rem 0 0;
	.container {
		@include g.mq {
			max-width: 41.4rem;
			min-width: 32rem;
		}
	}
	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
		@include g.mq {
			justify-content: space-between;
		}
	}
	&__logo {
		height: auto;
		&--bbb {
			width: 15.9rem;
			@include g.mq {
				width: 11.3rem;
			}
		}
		&--google {
			margin: 0 12rem;
			width: 14.2rem;
			@include g.mq {
				margin: 0;
				width: 10rem;
			}
		}
		&--trustpilot {
			width: 11.3rem;
			@include g.mq {
				width: 8rem;
			}
		}
	}
}
