@use "../global" as g;

/* ==========================================================================
    usage-flow
========================================================================== */
.page__usage-flow {
	.page__title {
		margin-bottom: 1.5rem;
		@include g.mq {
			margin-bottom: 1rem;
		}
	}
	.page__sub-title {
		font-size: 1.4rem;
		text-align: center;
	}
	.decide,
	.check {
		background-color: g.$bg-color;
	}
	.decide {
		@include g.mq {
			padding-bottom: 9.8rem;
		}
		&__title {
			margin-bottom: 4.8rem;
			@include g.mq {
				margin-bottom: 2.4rem;
			}
		}
		.plan {
			display: flex;
			justify-content: space-between;
			margin-bottom: 4.5rem;
			@include g.mq {
				flex-direction: column;
			}
			&__item {
				width: 30%;
				@include g.mq {
					width: 100%;
					padding: 3.4rem 2rem 4.2rem;
					background-color: #fff;
				}
			}
			&__icon {
				text-align: center;
				margin-bottom: 4rem;
				@include g.mq {
					margin-bottom: 0;
					margin-right: 1.9rem;
				}
			}
			&__title {
				font-size: 2.8rem;
				font-weight: bold;
				text-align: center;
				margin-bottom: 3.3rem;
				@include g.mq(lg) {
					font-size: 2.3rem;
				}
				@include g.mq {
					font-size: 1.8rem;
					margin-bottom: 0;
				}
			}
			&__content {
				position: relative;
				border: 1px solid #000;
				border-radius: 12px;
				padding: 3.5rem;
				background-color: #fff;
				@include g.mq(lg) {
					padding: 3.5rem 1.5rem;
				}
				&::after {
					position: absolute;
					top: 16.3rem;
					left: 50%;
					transform: translateX(-50%);
					content: "";
					width: 80%;
					height: 1px;
					background-color: #000;
					@include g.mq(lg) {
						top: 15rem;
					}
				}
			}
			&__detail {
				padding-bottom: 1.9rem;
				@include g.mq(lg) {
					max-width: 18rem;
					margin: 0 auto;
				}
				@include g.mq {
					max-width: none;
					padding-bottom: 1.7rem;
					border-bottom: 1px solid #E6E6E6;
				}
				&__text {
					font-size: 1.8rem;
					font-weight: bold;
					white-space: nowrap;
					@include g.mq(lg) {
						font-size: 1.6rem;
					}
				}
			}
			&__info {
				padding-top: 3rem;
				margin-bottom: 2rem;
				@include g.mq(xl) {
					width: 16rem;
					margin-left: auto;
					margin-right: auto;
				}
				@include g.mq {
					width: 100%;
					padding-top: 0.9rem;
				}
				&__header {
					margin-bottom: 0.5rem;
					font-weight: bold;
				}
				&__span {
					@include g.mq(xl) {
						margin-left: auto;
					}
				}
			}
			&__button {
				display: block;
				width: 9rem;
				color: #fff;
				font-size: 1.4rem;
				font-weight: bold;
				line-height: 1;
				text-align: center;
				border-radius: 2px;
				padding: 0.8rem 1rem;
				margin: 0 auto;
				transition: all 0.3s;
				@include g.mq {
					width: 100%;
					font-size: 1.8rem;
					padding: 1.5rem 0;
				}
				&:hover {
					opacity: 0.8;
				}
				&--economy {
					background-color: #4a6066;
				}
				&--standard {
					background-color: #e21096;
				}
				&--premium {
					background-color: #bf910b;
				}
			}
		}
		&__notes {
			&__text {
				position: relative;
				font-size: 1.2rem;
				margin-bottom: 0.3rem;
				padding-left: 1rem;
				&::before {
					content: "※";
					position: absolute;
					top: -1px;
					left: 0;
				}
			}
		}
		&__content--pc {
			@include g.mq {
				display: none;
			}
		}
		&__content--sp {
			display: none;
			@include g.mq {
				display: block;
				position: relative;
				.plan {
					&__header {
						display: flex;
						align-items: flex-end;
						padding-bottom: 1.2rem;
						margin-bottom: 2.2rem;
						border-bottom: 1px solid #707070;
					}
				}
			}
		}
		&__content__slide {
			overflow: visible;
			width: 34rem;
		}
		.swiper-horizontal>.swiper-pagination-bullets,
		.swiper-pagination-bullets.swiper-pagination-horizontal,
		.swiper-pagination-custom,
		.swiper-pagination-fraction {
			bottom: -5rem;
		}
		.swiper-pagination-bullet {
			width: 12px;
			height: 12px;
			background-color: #ccc;
			border-radius: 50%;
			transition: background-color 0.6s ease;
			opacity: 1;
			&-active,
			&:hover {
				background-color: #7DBF31;
				border-color: #7DBF31;
			}
		}
		.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
			margin: 0 8px;
		}
	}

	.organize {
		&__title {
			margin-bottom: 8rem;
			@include g.mq {
				margin-bottom: 3.5rem;
			}
		}
		&__list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include g.mq {
				flex-direction: column;
			}
		}
		&__item {
			width: 43.7%;
			@include g.mq(lg) {
				width: 46%;
			}
			@include g.mq {
				width: 100%;
			}
			&:not(:last-of-type) {
				@include g.mq {
					margin-bottom: 2rem;
				}
			}
			&:nth-of-type(-n+2) {
				margin-bottom: 11rem;
				@include g.mq {
					margin-bottom: 2rem;
				}
			}
			&:nth-of-type(3) {
				.organize__item__title {
					max-width: 40rem;
				}
			}
			&__header {
				margin-bottom: 1.5rem;
				@include g.mq {
					position: relative;
					display: flex;
					align-items: center;
					border-bottom: 1px solid #707070;
					padding-bottom: 2rem;
					margin-bottom: 2.4rem;
					overflow: visible;
					cursor: pointer;
					&::after,
					&.is-show::after {
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translate(-50%, 50%);
						content: "";
						border: 1px solid #707070;
						border-radius: 50%;
						width: 25px;
						height: 25px;
						background: #fff url(../images/usage-flow/icon_organize_triangle.svg) no-repeat center;
					}
					&.is-show::after {
						transform: translate(-50%, 50%) rotate(180deg);
					}
				}
			}
			&__icon {
				margin-bottom: 2rem;
				@include g.mq {
					width: 5.6rem;
					height: 5.3rem;
					margin-bottom: 0;
					margin-right: 1.2rem;
					flex-shrink: 0;
				}
			}
			&__title {
				white-space: pre-wrap;
			}
			&__body {
				@include g.mq {
					display: none;
					padding-bottom: 2.5rem;
					border-bottom: 1px solid #E6E6E6;
				}
			}
		}
	}

	.check {
		&__title {
			margin-bottom: 4.8rem;
			@include g.mq {
				margin-bottom: 3.5rem;
			}
		}
		&__list {
			display: flex;
			justify-content: space-between;
			margin-bottom: 9rem;
			@include g.mq {
				flex-direction: column;
				margin-bottom: 5rem;
			}
		}
		&__item {
			width: 30%;
			@include g.mq {
				width: 100%;
			}
			&:not(:last-of-type) {
				@include g.mq {
					margin-bottom: 4rem;
				}
			}
			&__icon {
				text-align: center;
				margin-bottom: 5rem;
				@include g.mq {
					width: 5.6rem;
					height: 5.6rem;
					margin-bottom: 0;
					margin-right: 1rem;
				}
			}
			&__content {
				@include g.mq {
					padding-top: 1rem;
				}
			}
			&__title {
				margin-bottom: 2rem;
				white-space: break-spaces;
				letter-spacing: -0.05em;
				@include g.mq {
					margin-bottom: 0.5rem;
					white-space: normal;
					letter-spacing: normal;
				}
			}
		}
		&__notes {
			&__text {
				position: relative;
				font-size: 1.2rem;
				margin-bottom: 0.3rem;
				padding-left: 1rem;
				&::before {
					content: "※";
					position: absolute;
					top: -1px;
					left: 0;
				}
			}
		}

	}

	.start {
		text-align: center;
		&__title {
			margin-bottom: 3rem;
		}
		&__desc {
      max-width: 80rem;
      margin: 0 auto;
      margin-bottom: 7.9rem;
      text-align: left;
			@include g.mq {
				margin-bottom: 1.6rem;
			}
		}
		&__list {
			position: relative;
			margin-bottom: 8.6rem;
			@include g.mq {
				margin-bottom: 2.4rem;
			}
			&::after {
				content: "";
				position: absolute;
				z-index: -1;
				top: 4rem;
				bottom: 9rem;
				left: 50%;
				border-left: 2px solid #ccc;
				transform: translate(-50%);
				@include g.mq {
					top: 4rem;
					left: 2.4rem;
				}
			}
		}
		&__item {
			display: flex;
			max-width: 40rem;
			margin-right: auto;
			margin-left: auto;
			@include g.mq {
				margin-left: 0;
			}
			&:not(:last-of-type) {
				margin-bottom: 12rem;
				@include g.mq {
					margin-bottom: 3.2rem;
				}
			}
			&:nth-of-type(1),
			&:nth-of-type(3) {
				transform: translateX(44%);
				@include g.mq {
					transform: none;
				}
			}
			&:nth-of-type(2) {
				flex-direction: row-reverse;
				transform: translateX(-44%);
				@include g.mq {
					transform: none;
					flex-direction: row;
				}
			}
			&__icon {
				flex-shrink: 0;
				&--right {
					margin-right: 0.5rem;
				}
				&--left {
					margin-left: 0.5rem;
					@include g.mq {
						margin-left: 0;
						margin-right: 0.5rem;
					}
				}
			}
			&__content {
				padding-top: 1rem;
				@include g.mq {
					padding-top: 1.5rem;
				}
				&--right {
					text-align: left;
				}
				&--left {
					text-align: right;
					@include g.mq {
						text-align: left;
					}
				}
			}
			&__title {
				margin-bottom: 0.5rem;
			}
			&__desc {
				&--left {
					text-align: left;
				}
			}
		}
		&__button {
			display: inline-block;
			font-size: 2.4rem;
			font-weight: 700;
			line-height: 2;
			color: #fff;
			background: #e85c41;
			padding: 1.2rem 2.4rem;
			transition: background-color .1s ease-in-out, color .1s ease-in-out, box-shadow .1s ease-in-out;
			cursor: pointer;
			-webkit-appearance: none;
			border-radius: 4px;
			transition: all 0.3s;
			@include g.mq {
				width: 100%;
				font-size: 1.6rem;
				line-height: 1.5;
			}
			&:hover {
				opacity: 0.8;
			}
		}
	}
}