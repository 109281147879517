@use "../global" as g;

/* ==========================================================================
    service
========================================================================== */
.page__service {
	.main-section {
		// padding: 8.2rem 0 5rem;
		@include g.mq {
			padding: 0;
		}
	}
	.contents .container {
		@include g.mq {
			max-width: 47rem;
		}
	}
	// pc用
	.service__content--pc {
		@include g.mq {
			display: none;
		}
	}
	// sp用
	.service__content--sp {
		display: none;
		@include g.mq {
			display: block;
		}
	}
	.service__content__header {
		@include g.mq {
			padding: 3.8rem 0;
			&--contact,
			&--reply {
				border-bottom: 1px solid #E5E5E5;
			}
		}
	}
	.service__content__body {
		@include g.mq {
			display: none;
		}
	}
	.service {
		&__list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include g.mq {
				flex-direction: column;
			}
		}
		&__item {
			position: relative;
			border: 1px solid #000;
			border-radius: 14px;
			width: 32%;
			padding: 2rem 3rem 3rem;
			@include g.mq(lg) {
				width: 48%;
			}
			@include g.mq {
				width: 100%;
				border: none;
				margin-bottom: 0;
				padding: 3.8rem 0 0;
			}
			&:nth-of-type(-n+3) {
				margin-bottom: 3.2rem;
				@include g.mq {
					margin-bottom: 0;
				}
			}
			&:nth-last-of-type(3) {
				@include g.mq(lg) {
					margin-bottom: 3.2rem;
				}
				@include g.mq {
					margin-bottom: 0;
				}
			}
			&--first {
				border: none;
				padding: 8rem 0 3rem;
				@include g.mq(lg) {
					padding-left: 3rem;
				}
			}
			&:not(.service__item--first)::after {
				position: absolute;
				top: 22.5rem;
				left: 50%;
				transform: translateX(-50%);
				content: "";
				width: 82.5%;
				height: 1px;
				background-color: #000;
				@include g.mq {
					position: static;
				}
			}
		}
		&__title {
			font-size: 3.6rem;
			font-weight: bold;
			margin-bottom: 4rem;
			@include g.mq {
				position: relative;
				font-size: 2.8rem;
				text-align: center;
				margin-bottom: 0;
				&::after,
				&.is-show::after {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					content: "";
					width: 30px;
					height: 30px;
				}
				&::after {
					background-image: url(../images/service/icon_plus.svg);
				}
				&.is-show::after {
					background-image: url(../images/service/icon_minus.svg);
				}
			}
		}
		&__tool {
			&__item {
				position: relative;
				font-size: 3.6rem;
				font-weight: bold;
				padding-left: 3.5rem;
				line-height: 1.2;
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: "・";
				}
			}
		}
		&__header {
			text-align: center;
			margin-bottom: 4rem;
			@include g.mq {
				margin-bottom: 3.2rem;
			}
			&__inner {
				@include g.mq {
					display: flex;
					text-align: left;
				}
			}
		}
		&__icon {
			width: 104px;
			height: 104px;
			margin: 0 auto 0.9rem;
			@include g.mq {
				width: 53px;
				height: 54px;
				margin: 0 1.9rem 0 0;
			}
		}
		&__sub-title {
			font-size: 3.2rem;
			font-weight: bold;
			margin-bottom: 0.3rem;
			@include g.mq {
				font-size: 1.8rem;
				margin-bottom: 0;
			}
		}
		&__desc {
			font-size: 1.4rem;
			white-space: nowrap;
			@include g.mq {
				font-size: 1.2rem;
			}
			&--long {
				letter-spacing: -0.1em;
				@include g.mq(xl) {
					margin-left: -2rem;
				} 
				@include g.mq(lg) {
					margin-left: 0;
				}
			}
		}
		&__detail {
			@include g.mq {
				padding-bottom: 4rem;
				background: linear-gradient(#fff, #F8F8F8);
			}
			&__title {
				font-size: 1.8rem;
				font-weight: bold;
				margin-bottom: 1.7rem;
				white-space: break-spaces;
				@include g.mq {
					font-size: 1.6rem;
				}
			}
			&__list {
				margin-bottom: 0.5rem;
			}
			&__item {
				position: relative;
				font-size: 1.4rem;
				padding-left: 1.5rem;
				@include g.mq {
					font-size: 1.2rem;
				}
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: "・";
				}
			}
			&__text {
				font-size: 1.4rem;
				@include g.mq {
					font-size: 1.2rem;
				}
			}
		}
	}

	// contact
	.contact {
		padding-top: 0;
	}

	// reply
	.reply {
		background-color: g.$bg-color;
		@include g.mq {
			background-color: #fff;
		}
	}

	// confirm
	.confirm {
		.service__content__body {
			border-top: 1px solid #E5E5E5;
		}
	}
}